/*=========================================================================================
  File Name: moduleBranch.js
  Description: AllAllCompanyParty Module
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


import state from './moduleBranchState.js'
import mutations from './moduleBranchMutations.js'
import actions from './moduleBranchActions.js'
import getters from './moduleBranchGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}