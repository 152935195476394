<template>
  <div id="blank-layout">
    <v-app>
      <body id="kt_body" class="bg-body">
        <div class="d-flex flex-column flex-root">
          <div
            class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
            :style="{
              backgroundImage: `url(${require('@/assets/media/illustrations/sketchy-1/14.png')})`,
            }"
          >
            <slot />
            <!-- <v-icon left color="red"> mdi-heart </v-icon> -->
            <div class="d-flex align-items-center flex-center flex-column-auto">
              <default-footer/>
            </div>
          </div>
        </div>
      </body>
    </v-app>
  </div>
</template>

<script>
import DefaultFooter from '@/components/DefaultFooter.vue';
export default {
  components: { DefaultFooter },
  data() {
    return {
      CurrentYear: new Date().getFullYear(),
    };
  },
};
</script>