/*=========================================================================================
  File Name: moduleBranch.js
  Description: AllAllCompanyParty Module
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


import state from './moduleTransferState.js'
import mutations from './moduleTransferMutations.js'
import actions from './moduleTransferActions.js'
import getters from './moduleTransferGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}