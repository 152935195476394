/*=========================================================================================
  File Name: moduleAssetType.js
  Description: AllAllCompanyParty Module
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


import state from './moduleDistrictState.js'
import mutations from './moduleDistrictMutations.js'
import actions from './moduleDistrictActions.js'
import getters from './moduleDistrictGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}