/*=========================================================================================
  File Name: moduleAssetType.js
  Description: AllAllCompanyParty Module
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


import state from './moduleCategorySubState.js'
import mutations from './moduleCategorySubMutations.js'
import actions from './moduleCategorySubActions.js'
import getters from './moduleCategorySubGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}