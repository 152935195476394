/*=========================================================================================
  File Name: moduleBranch.js
  Description: AllAllCompanyParty Module
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


import state from './moduleInwardState.js'
import mutations from './moduleInwardMutations.js'
import actions from './moduleInwardActions.js'
import getters from './moduleInwardGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}