/*=========================================================================================
  File Name: moduleAssetCategory.js
  Description: AllAllCompanyParty Module
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


import state from './moduleAssetCategoryState.js'
import mutations from './moduleAssetCategoryMutations.js'
import actions from './moduleAssetCategoryActions.js'
import getters from './moduleAssetCategoryGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}