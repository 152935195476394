<template>
  <!--begin::Header-->
  <div id="kt_header" class="header align-items-stretch">
    <!--begin::Container-->
    <div
      class="container-xxl d-flex align-items-stretch justify-content-between"
    >
      <!--begin::Aside mobile toggle-->
      <!--end::Aside mobile toggle-->
      <!--begin::Logo-->
      <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
        <img alt="Logo" src="/images/logo.png" class="h-20px h-lg-30px" />
      </div>
      <!--end::Logo-->
      <!--begin::Wrapper-->
      <div
        class="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
      >
        <!--begin::Menu-->
        <!-- <nav-bar-menu /> -->

        <div class="hide-menu">
          <header-menu-lists />
        </div>

        <!--begin::Toolbar wrapper-->
        <div class="d-flex align-items-stretch flex-shrink-0">
          <!--begin::User menu-->
          <v-btn plain class="mt-3 ml-3" @click.prevent="openBranchDialog">
            <v-icon left> mdi-map-marker </v-icon>
            {{ PrimaryBranch.BranchCode }} - {{ PrimaryBranch.BranchName }}
          </v-btn>
          <div
            class="d-flex align-items-center ms-1 ms-lg-3"
            id="kt_header_user_menu_toggle"
          >
            <b-sidebar id="user-sidebar" backdrop no-header shadow right>
              <user-sidebar />
            </b-sidebar>

            <!--begin::Menu wrapper-->
            <div class="cursor-pointer symbol symbol-30px symbol-md-40px">
              <img
                src="../assets/media/avatars/300-1.jpg"
                alt="user"
                v-b-toggle.user-sidebar
              />
            </div>

            <div class="menu-item">
              <!-- <router-link to="/login" class="menu-link px-5"> -->
              <div class="menu-link px-5" @click="LogoutUser">
                <v-icon class="text-danger" size="20">
                  mdi-logout-variant
                </v-icon>
                &nbsp;
                <span class="log-out-text mr-1 text-danger">Log Out</span>
              </div>
              <!-- </router-link> -->
            </div>

            <!--end::Menu wrapper-->
          </div>
          <!--end::User menu-->
          <!--begin::Header menu toggle-->
          <div
            class="d-flex align-items-center d-lg-none ms-2 me-n3"
            title="Show header menu"
          >
            <div
              class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
              id="kt_header_menu_mobile_toggle"
            >
              <v-app-bar-nav-icon
                v-b-toggle.sidebar-right
                class="mobile-nav-icon"
              />
              <b-sidebar id="sidebar-right" right backdrop no-header shadow>
                <div class="mt-3">
                  <img
                    alt="Logo"
                    src="/images/logo.png"
                    class="h-20px h-lg-30px"
                  />
                </div>
                <v-divider />
                <div class="text-left">
                  <header-menu-lists />
                </div>
              </b-sidebar>
            </div>
          </div>
          <!--end::Header menu toggle-->
        </div>
        <!--end::Toolbar wrapper-->
      </div>
      <!--end::Wrapper-->
    </div>
    <v-dialog v-model="showBranchDialog" persistent max-width="70%">
      <change-primary-branch
        v-if="showBranchDialog"
        @hideDialog="hideAddRecordPrompt($event)"
      ></change-primary-branch>
    </v-dialog>
    <!--end::Container-->
  </div>
  <!--end::Header-->
</template>

<script>
import Common from "@/Common.vue";
import UserSidebar from "@/components/UserSidebar.vue";
import HeaderMenuLists from "@/components/HeaderMenuLists.vue";
import ChangePrimaryBranch from "@/components/ChangePrimaryBranch.vue";

export default {
  mixins: [Common],
  components: {
    UserSidebar,
    HeaderMenuLists,
    ChangePrimaryBranch,
  },
  data() {
    return {
      AddRecordPrompt: false,
    };
  },
  computed: {
    PrimaryBranch() {
      console.log("PrimaryBranch computed called");
      var tr = this.getBranchData();
      console.log({ tr });
      return tr;
    },
    showBranchDialog() {
      console.log("showBranchDialog computed called");
      var data = this.$store.getters["staffStore/showBranchDialog"];
      console.log({ data });
      return data;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.checkPrimaryBranch();
    },
    openBranchDialog() {
      console.log("openBranchDialog called");
      this.$store.dispatch("staffStore/setBranchDialog", true);
    },
    hideAddRecordPrompt(flag) {
      console.log("hideAddRecordPrompt called");
      console.log({ flag });
      this.$store.dispatch("staffStore/setBranchDialog", flag);
      if (flag) {
        // this.refreshPageData();
      }
    },
  },
  beforeMount() {
    if (!this.$session.exists()) {
      console.log("your session is expired");
      this.$router.push("/");
    } else {
      this.refreshPageData();
    }
  },
};
</script>

<style lang="scss">
#kt_header {
  @media (max-width: 991px) {
    .hide-menu {
      display: none !important;
    }
    .log-out-text {
      display: none !important;
    }
  }
  @media (min-width: 992px) {
    .mobile-nav-icon {
      display: none !important;
    }
  }
  @media (max-width: 1263px) {
    .lg-men {
      // margin-left: -430px;
      margin-top: -8px;
    }
  }
}
</style>