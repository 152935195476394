/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import themeConfig from '@/themeConfig.js'

// var navbarSearchAndPinList = {}
/*
var navbarSearchAndPinList = {
  actionIcon: 'StarIcon',
  highlightColor: 'warning',
  data: []
}
*/

const state = {
  isSidebarActive: true,
  breakpoint: null,
  sidebarWidth: "default",
  reduceButton: themeConfig.sidebarCollapsed,
  bodyOverlay: false,
  theme: themeConfig.theme || 'light',
  themePrimaryColor: themeConfig.primary,
}

export default state
