/*=========================================================================================
  File Name: moduleBranchMutations.js
  Description: AllCompanyParty Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


export default {
  ADD_NEW_RECORD(state, dataObj) {
    const newData = Object.assign({}, dataObj);
    state.dataArray.push(newData);
  },
  SET_PARENT_GROUP_NAME(state, payload) {
    state.dataArray[payload.sno].parent_group_name = payload.title;
  },
  SET_PRICE_GROUP_NAME(state, payload) {
    state.dataArray[payload.sno].group_name = payload.title;
  },
  SET_ACTIVE_STATUS(state, payload) {
    state.dataArray[payload.sno].active_status = payload.title;
  },
  SET_STATUS_FLAG(state, payload) {
    state.StatusFlag = payload;
  },
  SET_DATA(state, payload) {
    console.log('SET_DATA called', {payload});
    state.data = payload;
  },
  LOAD_ALL_RECORDS(state, records) {
    state.dataArray = records;
  }
}
