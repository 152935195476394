<!-- =========================================================================================
    File Name: EmployeesAddNew.vue
    Description: Add new Employees
    ----------------------------------------------------------------------------------------
    Item Name: Vaagai Tecknowledge - Easy Admin
    Author: Web Team
    Author URL: http://www.vaagai.org.in/
========================================================================================== -->


<template>
  <div id="employee-create">
    <v-card>
      <v-app-bar
        :color="themeConfig.myPrimaryColor"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ pageDescription }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-row wrap v-if="StartupLoadingFlag">
          <v-col cols="12" md="4" v-for="n in 6" :key="n">
            <v-skeleton-loader
              v-bind="attrs"
              type="paragraph"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-card
          elevation="6"
          outlined
          id="my-data-table"
          v-if="!StartupLoadingFlag"
        >
          <v-card-text>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="2" md="2">
                <label class="my-label"> Approval Status </label>
                <br />
                <v-chip
                  draggable
                  dark
                  :color="recordObj.ApprovalStatusBgColor"
                  :text-color="recordObj.ApprovalStatusTextColor"
                  >{{ recordObj.ApprovalStatusTxt }}</v-chip
                >
              </v-col>
              <v-col cols="12" sm="6" lg="2" md="2">
                <label class="my-label"> Verified Status </label>
                <br />
                <v-chip
                  draggable
                  dark
                  :color="recordObj.VerifiedStatusBgColor"
                  :text-color="recordObj.VerifiedStatusTextColor"
                  >{{ recordObj.VerifiedStatusTxt }}</v-chip
                >
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label class="my-label">Billed To </label>
                <h5>{{ recordObj.BilledBranchName }}</h5>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label class="my-label">Shipped To </label>
                <h5>{{ recordObj.ShippedBranchName }}</h5>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label class="my-label">Purchase Order Date </label>
                <h5>{{ recordObj.PurchaseOrderDateTxt }}</h5>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label class="my-label">Purchase Order Number </label>
                <h5>{{ recordObj.PurchaseOrderNumber }}</h5>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label class="my-label">Vendor </label>
                <h5>{{ recordObj.VendorName }}</h5>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h5>
                  <a :href="recordObj.PoPath" target="_blank" download>
                    Download PurchaseOrder
                  </a>
                </h5>
              </v-col>

              <v-col cols="12" sm="6" lg="3" md="3">
                <h5>
                  <a :href="recordObj.PoPath" target="_blank" download>
                    Download Quotation
                  </a>
                </h5>
              </v-col>
            </v-row>
            <v-divider />
            <v-row wrap>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label class="my-label">Sub Total </label>
                <h5>{{ recordObj.SubTotal }}</h5>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label class="my-label">Discount </label>
                <h5>{{ recordObj.Discount }}</h5>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label class="my-label">Freight Amount </label>
                <h5>{{ recordObj.FreightAmount }}</h5>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label class="my-label">SGST </label>
                <h5>{{ recordObj.SgstAmount }}</h5>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label class="my-label">CGST </label>
                <h5>{{ recordObj.CgstAmount }}</h5>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label class="my-label">IGST </label>
                <h5>{{ recordObj.IgstAmount }}</h5>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label class="my-label"> Round Off </label>
                <h5>{{ recordObj.RoundOff }}</h5>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label class="my-label"> Total Amount </label>
                <h5>{{ recordObj.GrandTotal }}</h5>
              </v-col>
            </v-row>
            <v-divider />
            <v-row wrap v-if="recordObj.ProductsCount > 0">
              <v-col align="center" cols="12" sm="12" lg="12" md="12">
                <label class="my-label"> Purchase Order Product Details </label>
                <v-data-table
                  no-data-text="No puchase products found"
                  class="elevation-1"
                  disable-sort
                  :items="recordObj.Products"
                  :headers="recordObj.ProductHeader"
                  hide-default-footer
                  disable-pagination
                >
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click.prevent="closePrompt"
          medium
          rounded
          outlined
          elevation="30"
          class="ml-3"
          :color="themeConfig.primary"
        >
          <v-icon> mdi-cancel </v-icon>
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import companyConfig from "@/company_config.json";
import common from "@/Common.vue";
import themeConfig from "@/themeConfig.js";

export default {
  mixins: [common],
  components: {},
  props: {
    rowData: {
      required: true,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      StartupLoadingFlag: false,
      themeConfig: themeConfig,
      LoadingFlag: false,

      ResultFlag: false,
    };
  },
  computed: {
    recordObj() {
      return this.$store.getters["purchaseOrderStore/data"];
    },
  },
  watch: {
    recordObj: function () {
      console.log("watch recordObj called");

      var tr = this.recordObj;
      console.log({ tr });

      var n = Object.keys(tr).length;
      console.log({ n });
      if (n > 0) {
      }
    },
  },
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog", this.ResultFlag);
    },
    refreshPageData() {
      console.log("refreshPageData called");

      var tr = this.rowData;
      console.log({ tr });

      var headers = this.getApiHeaders();
      console.log({ headers });

      var server_url = companyConfig.apiURL;
      console.log({ server_url });

      const loader = this.$loading.show({});

      var upload = {
        UserInterface: 1,
        PurchaseOrder: tr.PurchaseOrderId,
        Branch: tr.BilledBranchId,
        PurchaseOrderNumber: tr.PurchaseOrderNumber,
      };
      console.log({ upload });

      var getAllRecords = {};
      getAllRecords.headers = headers;
      getAllRecords.upload = upload;
      getAllRecords.list_url = server_url + "api/purchase-order/show";
      getAllRecords.loading = loader;
      getAllRecords.notify = this.sweetAlert;
      getAllRecords.http = this.$http;
      this.$store.dispatch("purchaseOrderStore/loadSingleData", getAllRecords);
    },
  },
  beforeMount() {
    if (!this.$session.exists()) {
      console.log("your session is expired");
      this.$router.push("/");
    } else {
      this.refreshPageData();
    }
  },
};
</script>
<style lang="scss">
#employee-create {
}
</style>
