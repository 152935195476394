import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "login",
  },
  {
    path: '/login',
    name: 'login',
    meta: { layout: "blank" },
    component: () => import('@/views/LoginPage.vue')
  },
  {
    path: '/dashboard/branch-manager',
    name: 'dashboard',
    component: () => import('@/views/dashboard/DashboardBranchManager.vue')
  },
  {
    path: '/access-denied',
    name: 'Access Denied',
    component: () => import('@/views/AccessDenied.vue')
  },

  // Branch

  {
    path: '/branch/list',
    name: 'Branch List',
    component: () => import('@/views/branch/BranchList.vue')
  },
  {
    path: '/branch/create',
    name: 'Add New Branch',
    component: () => import('@/views/branch/BranchCreate.vue')
  },
  {
    path: '/branch/edit',
    name: 'Edit Branch',
    component: () => import('@/views/branch/BranchEdit.vue')
  },

  // Staff

  {
    path: '/staff/list',
    name: 'Staff List',
    component: () => import('@/views/staff/StaffList.vue')
  },
  {
    path: '/staff/create',
    name: 'Add New Staff',
    component: () => import('@/views/staff/StaffCreate.vue')
  },
  {
    path: '/staff/edit',
    name: 'Edit Staff',
    component: () => import('@/views/staff/StaffEdit.vue')
  },

  // Vendor

  {
    path: '/vendor/list',
    name: 'Vendor List',
    component: () => import('@/views/vendor/VendorList.vue')
  },
  {
    path: '/vendor/create',
    name: 'Add New Vendor',
    component: () => import('@/views/vendor/VendorCreate.vue')
  },
  {
    path: '/vendor/edit',
    name: 'Edit Vendor',
    component: () => import('@/views/vendor/VendorEdit.vue')
  },

  // Asset

  {
    path: '/asset/list',
    name: 'Asset List',
    component: () => import('@/views/product/ProductList.vue')
  },
  {
    path: '/asset/create',
    name: 'Add New Asset',
    component: () => import('@/views/product/ProductCreate.vue')
  },
  {
    path: '/asset/edit',
    name: 'Edit Asset',
    component: () => import('@/views/product/ProductEdit.vue')
  },

  // accessories

  {
    path: '/accessories/lists',
    name: 'Accessories List',
    component: () => import('@/views/accessories/AccessoriesList.vue')
  },
  {
    path: '/accessories/create',
    name: 'Add New Accessories',
    component: () => import('@/views/accessories/AccessoriesCreate.vue')
  },

  //Category Sub

  {
    path: '/category-sub/list',
    name: 'category Sub List',
    component: () => import('@/views/category_sub/CategorySubList.vue')
  },

  // Purchase Order

  {
    path: '/purchase-order/list',
    name: 'Purchase Order List',
    component: () => import('@/views/purchase_order/PurchaseOrderList.vue')
  },
  {
    path: '/purchase-order/create',
    name: 'Purchase Order Create',
    component: () => import('@/views/purchase_order/PurchaseOrderCreate.vue')
  },

  // Asset Installation

  {
    path: '/asset-installation/list',
    name: 'Asset Installed List',
    component: () => import('@/views/product_install/ProductInstallList.vue')
  },
  {
    path: '/asset-installation/new',
    name: 'New Asset Installation',
    component: () => import('@/views/product_install/ReviewProductInstall.vue')
  },

  // Asset Transfer

  {
    path: '/asset-transferred/list',
    name: 'Asset Transferred List',
    component: () => import('@/views/product_transfer/ProductTransferredList.vue')
  },
  {
    path: '/asset-transfer/new',
    name: 'New Asset Transfer',
    component: () => import('@/views/product_transfer/ReviewProductTransfer.vue')
  },

  // Menu List

  {
    path: "/menu/lists",
    name: "Menu List",
    component: () => import("@/views/menu/MenuList.vue")
  },

  // Menu List

  {
    path: "/menu-permission/lists",
    name: "Menu Permission List",
    component: () => import("@/views/menu/MenuPermissionList.vue")
  },

  // Bulk Upload

  {
    path: "/asset/bulk-upload",
    name: "Asset Bulk Upload",
    component: () => import("@/views/bulk_upload/ProductBulkUpload.vue")
  },

  // Job Order

  {
    path: '/job-order/list',
    name: 'Job Order List',
    component: () => import('@/views/job_order/JobOrderList.vue')
  },
  {
    path: '/job-order/create',
    name: 'New Job Order',
    component: () => import('@/views/job_order/JobOrderCreate.vue')
  },

  // asset category

  {
    path: '/asset-category/lists',
    name: 'Asset Category List',
    component: () => import('@/views/asset_category/AssetCategoryList.vue')
  },

  // asset type

  {
    path: '/asset-type/lists',
    name: 'Asset Type List',
    component: () => import('@/views/asset_type/AssetTypeList.vue')
  },

  // state setting

  {
    path: '/states/lists',
    name: 'State List',
    component: () => import('@/views/state/StateList.vue')
  },

  // district setting

  {
    path: '/district/lists',
    name: 'District List',
    component: () => import('@/views/district/DistrictList.vue')
  },

  // city setting

  {
    path: '/city/lists',
    name: 'City List',
    component: () => import('@/views/city/CityList.vue')
  },

]

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log({ to });
    console.log({ from });
    console.log({ savedPosition });
    return { x: 0, y: 0 }
  }
});

router.beforeEach(async (to, from, next) => {
  var ToName = to.name;
  console.log({ ToName });

  var fromPath = from.path;
  var toPath = to.path;
  console.log("navigation from=" + fromPath + ", to=" + toPath);

  var token = localStorage.getItem("token");
  token = token == (null || undefined) ? "" : token;
  console.log({ token });

  if (
    to.path == "/" ||
    to.path == "/login" ||
    to.path == "/*"
  ) {
    console.log("navigation true");
    next();
  } else {
    console.log("navigation false");
    if (token != "") {
      console.log("token is valid");
      next();
    } else {
      console.log("session is expired");
      router.push("/login");
    }
  }
});

export default router