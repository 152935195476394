/*=========================================================================================
  File Name: moduleAssetTypeAction.js
  Description: AllCompanyParty Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/

export default {
  setParentGroupName({ commit }, payload) {
    commit('SET_PARENT_GROUP_NAME', payload);
  },
  setPriceGroupName({ commit }, payload) {
    commit('SET_PRICE_GROUP_NAME', payload);
  },
  setActiveStatus({ commit }, payload) {
    commit('SET_ACTIVE_STATUS', payload);
  },
  loadSingleData({ commit }, payload) {
    console.log('branch loadSingleData called');

    var output = "";
    var flag = 0;
    var records = {};

    commit('SET_DATA', records);

    payload.http({
      url: payload.list_url,
      method: 'POST',
      data: payload.upload,
      headers: payload.headers
    })
      .then(function (response) {
        console.log('response=' + JSON.stringify(response));

        payload.loading.hide();

        output = response.data.output;
        flag = response.data.flag;
        console.log('output=' + output + ', flag=' + flag);

        records = response.data.records;
        console.log({ records });

        commit('SET_DATA', records);

        if (payload.alert_flag) {
          if (flag == 1) {
            payload.notify('success', output, true);
          } else {
            payload.notify('error', output, false);
          }
        }
      })
      .catch(function (error) {
        console.log('error=' + error);
      });
  },


  loadAllRecords({ commit }, payload) {

    console.log('party loadAllRecords called');

    var output = "";
    var flag = 0;
    var records = [];

    var url = payload.list_url;
    console.log('url=' + url);

    var upload = payload.list_where;
    payload.http({
      url: url,
      method: 'POST',
      data: upload,
      headers: payload.headers
    })
      .then(function (response) {
        // payload.updateFlags({ StartupLoadingFlag: true, LoadingFlag: false });

        console.log('response=' + JSON.stringify(response));
        payload.loading.hide();
        // thisIns.StartupLoadingFlag = false;
        // thisIns.LoadingFlag = false;
        output = response.data.output;
        flag = response.data.flag;
        console.log('output=' + output + ', flag=' + flag);

        records = response.data.records;
        console.log({ records });
        commit('LOAD_ALL_RECORDS', records);

        if (payload.alert_flag) {
          if (flag == 1) {
            // payload.notify('success', output, true);
          } else {
            payload.notify('error', output, false);
          }
        }
      })
      .catch(function (error) {
        console.log('error=' + error);
      });
    // .finally(() => {
    //   // Reset the flags after loading completes
    //   payload.updateFlags({ StartupLoadingFlag: false, LoadingFlag:false });
    // });
  },
  addRecordDetails({ commit, dispatch }, payload) {
    var output = "";
    var flag = 0;

    commit('SET_STATUS_FLAG', false);

    payload.http({
      url: payload.add_url,
      method: 'POST',
      data: payload.upload,
      headers: payload.headers
    })
      .then(function (response) {
        console.log('response=' + JSON.stringify(response));

        payload.loading.hide();

        output = response.data.output;
        flag = response.data.flag;
        console.log('output=' + output + ', flag=' + flag);

        commit('SET_STATUS_FLAG', flag);

        if (flag == 1) {
          if (payload.redirect_flag) {
            payload.router.push(payload.redirect_url).catch(() => true);
          }
          payload.notify('success', output, true);
        } else {
          payload.notify('error', output, false);
        }
      })
      .catch(function (error) {
        console.log('error=' + error);
      });
  },
}