/*=========================================================================================
  File Name: moduleBranchGetters.js
  Description: AllCompanyParty Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vaagai Tecknowledge - Easy Admin
  Author: Web Team
  Author URL: http://www.vaagai.org.in
==========================================================================================*/


export default {
  dataArrayLength: state => state.dataArray.length,
  dataList: state => {
    return state.dataArray;
  },
  data: state => {
    console.log('branch data getter');
    var data = state.data;
    console.log({ data });
    return data;
  },
  StatusFlag: state => {
    return state.StatusFlag;
  },
}